import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/technology/add-cover-epub-pdf-mobi-book/",
  "date": "2014-01-24",
  "title": "HOW TO ADD A COVER TO AN EPUB, PDF OR A KINDLE MOBI BOOK",
  "author": "admin",
  "tags": ["technology", "tips"],
  "featuredImage": "feature.jpg",
  "excerpt": "EPUB, PDF and Kindle MOBI books converted from other formats or downloaded from torrent sites are sometimes cover-missing, which inevitably makes the whole eBook-reading journey less satisfactory"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Take it easy. Here I will explain how to add a cover to an EPUB, PDF or a Kindle MOBI book with free Calibre.`}</p>
    <ul>
      <li parentName="ul">{`Download and install `}<a parentName="li" {...{
          "href": "https://calibre-ebook.com/download"
        }}>{`Calibre`}</a>{` on your computer.`}</li>
      <li parentName="ul">{`Launch Calibre and drag & drop your cover-missing books to it.`}</li>
      <li parentName="ul">{`Select a book and click `}<inlineCode parentName="li">{`Edit metadata`}</inlineCode>{` on top menu bar.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2014-01-24-how-to-add-a-cover-to-an-epub-pdf-or-a-kindle-mobi-book/01.jpg",
        "alt": "Edit metadata"
      }}></img></p>
    <ul>
      <li parentName="ul">{`Select Download cover on the following window. Calibre will auto search for the EPUB (PDF / MOBI) cover online. You can edit book title, author, etc on this window as well.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2014-01-24-how-to-add-a-cover-to-an-epub-pdf-or-a-kindle-mobi-book/02.jpg",
        "alt": "Download cover"
      }}></img></p>
    <ul>
      <li parentName="ul">{`Click to select a cover from the search results and click `}<inlineCode parentName="li">{`OK`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`The cover will get updated at once. Click `}<inlineCode parentName="li">{`OK`}</inlineCode>{`.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2014-01-24-how-to-add-a-cover-to-an-epub-pdf-or-a-kindle-mobi-book/03.jpg",
        "alt": "Click OK"
      }}></img></p>
    <ul>
      <li parentName="ul">{`Go back to the main panel, you will find the cover added to the EPUB book accordingly.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/2014-01-24-how-to-add-a-cover-to-an-epub-pdf-or-a-kindle-mobi-book/04.jpg",
        "alt": "Cover added"
      }}></img></p>
    <p>{`If you have got a cover on your computer, you can also select `}<inlineCode parentName="p">{`Browse`}</inlineCode>{` on `}<inlineCode parentName="p">{`Edit Metadata`}</inlineCode>{` screen to add cover.`}</p>
    <p><img parentName="p" {...{
        "src": "https://2.bp.blogspot.com/-XZPphKjTb80/URCa9E3F15I/AAAAAAAABow/v4AUbT_1eu8/s1600/browse.png",
        "alt": "Change cover"
      }}></img></p>
    <p>{`The cover will be added as well. Click “OK” to go back to the main screen.`}</p>
    <p>{`Covers have been added to the EPUB and PDF books.`}</p>
    <p>{`When finishing adding covers to EPUB / PDF / MOBI books, don’t forget to select the title and convert the book to its original format again. For example, if the original is `}<inlineCode parentName="p">{`PDF`}</inlineCode>{`, convert it to `}<inlineCode parentName="p">{`PDF`}</inlineCode>{` again after adding a cover to it.`}</p>
    <p>{`Set the `}<inlineCode parentName="p">{`Output format`}</inlineCode>{` as `}<inlineCode parentName="p">{`PDF`}</inlineCode>{` (according to the original) and click `}<inlineCode parentName="p">{`OK`}</inlineCode>{` to convert.`}</p>
    <p>{`When the `}<inlineCode parentName="p">{`status`}</inlineCode>{` in the lower right corner turns to `}<inlineCode parentName="p">{`0`}</inlineCode>{`, the conversion is done.`}</p>
    <p>{`Click `}<inlineCode parentName="p">{`Click to open`}</inlineCode>{` to get the converted book (PDF), you will find both the converted PDF and the original one with `}<inlineCode parentName="p">{`original`}</inlineCode>{` characters added to the file name under the output folder.`}</p>
    <p><img parentName="p" {...{
        "src": "https://4.bp.blogspot.com/-r9UvXuxCgpc/URCftZJECYI/AAAAAAAABqg/JiQFwLsQCwo/s1600/final_output.png",
        "alt": "Output folder"
      }}></img></p>
    <p>{`Add the newly created book (PDF) to Calibre and check again (with all originals deleted from Calibre). You will find the cover has been successfully added to the PDF book this time.`}</p>
    <p>{`Now, we learnt two steps to add a cover to an EPUB/PDF/MOBI eBook by yourself: Edit metadata and convert to its original format. Drop a comment below if you have any problem.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      